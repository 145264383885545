div.VPAID-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div.vjs-vpaid-ad div.vjs-progress-control,
div.vjs-vpaid-ad div.vjs-time-controls,
div.vjs-vpaid-ad div.vjs-time-divider {
  display: none;
}

div.vjs-vpaid-ad.vjs-vpaid-flash-ad div.VPAID-container {
  background-color: #000000;
}

div.vjs-vpaid-ad .vjs-tech {
  z-index: 0;
}
