/**
 * videojs.ads.css
 */

/* Ad playback */
.vjs-ad-playing .vjs-progress-control {
  pointer-events: none;
}

.vjs-ad-playing .vjs-play-control.vjs-paused,
.vjs-ad-playing .vjs-volume-level,
.vjs-ad-playing .vjs-play-progress {
  background-color: #ffe400 !important;
}

div.vast-skip-button {
  display: block;
  position: absolute;
  bottom: 20%;
  right: 0;
  background-color: #000;
  color: white;
  font-size: 15px;
  font-weight: bold;
  width: auto;
  padding: 8px;
  z-index: 2;
  border: 1px solid white;
  border-right: none;
}

.vast-skip-button.enabled {
  cursor: pointer;
  color: #fff;
}

.vast-skip-button.enabled:hover {
  cursor: pointer;
  background: #333;
}

.vast-blocker {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.vast-skip-button.enabled:after {
  content: ">>";
  position: relative;
  top: 1px;
  margin-left: 8px;
}
/* Ad loading */
.vjs-ad-playing.vjs-vast-ad-loading .vjs-loading-spinner {
  display: block;
  z-index: 10;

  /* only animate when showing because it can be processor heavy */
  /* animation */
  -webkit-animation: spin 1.5s infinite linear;
  -moz-animation: spin 1.5s infinite linear;
  -o-animation: spin 1.5s infinite linear;
  animation: spin 1.5s infinite linear;
}

.vjs-vast-ad-loading div.vjs-big-play-button {
  display: none!important;
}

.vjs-ad-playing .vjs-slider-handle:before{
  display: none;
}

.vjs-ad-playing  .vjs-live-controls{
  display: none;
}